const $ = require('jquery');
global.$ = global.jQuery = $;

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import 'bootstrap';

$(document).ready(function(){
    $("#cta-menu-mobile").on('click', function(){
        $("#cta-menu-mobile").toggleClass('active bg-white text-primary bg-transparent text-white');
        $("header").toggleClass('show');
    })
});

